import React from 'react';
import { Link } from 'react-router-dom';

const HeaderMenu = () => {
  return (
    <div className="bg-black p-3 flex justify-center items-center w-full mt-5">
        <Link to="/" className= "text-2xl text-white mr-5">Home</Link>
        <Link to="/houses" className="text-2xl text-white mr-5">Houses</Link>
        <Link to="/contact" className="text-2xl text-white">Contact</Link>
    </div>
  )
}

export default HeaderMenu;
