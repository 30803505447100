import React from 'react';
import Video from "../assets/marathion.mp4";

const BackgroundVideo = () => {
  return (
    <div className="h-96 md:h-5/6 pb-1 w-full">
        <video className="w-full object-none h-80" autoPlay muted loop>
            <source src={Video} type="video/mp4" />
        </video>
    </div>
  )
}

export default BackgroundVideo;
