import React, { useState } from 'react';

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // send data to server or an email
    alert(`Thank you ${name}, we received your message`);
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mr-12 ml-12 mb-12 mt-12">
    <form className="bg-white p-6 rounded-lg" onSubmit={handleSubmit}>
      <h2 className="text-lg font-medium mb-4">Contact Us</h2>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">Name</label>
        <input 
          className="bg-gray-200 p-2 rounded-lg w-full"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="John Doe"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">Email</label>
        <input 
          className="bg-gray-200 p-2 rounded-lg w-full"
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="example@email.com"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-medium mb-2">Message</label>
        <textarea
          className="bg-gray-200 p-2 rounded-lg w-full h-full"
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Type your message here..."
          />
          </div>
          <button className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600">Submit</button>
          </form>
          <div>
          <iframe title="GoogleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50678.290988128705!2d25.345828495444586!3d37.45143748373056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a2b8b4f03c9b91%3A0x78d164471e79b4a1!2sMykonos!5e0!3m2!1sen!2sgr!4v1673816258929!5m2!1sen!2sgr"
              width="100%"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
            </div>
          )
          }
          
          export default ContactForm;