import React from "react";
import LogoImg from "../assets/logo.png";
import HeaderMenu from "./HeaderMenu";
import { Link } from "react-router-dom";

const Header = () => {

    return (
        <div className="flex flex-col items-center justify-center">
            <div>
                <Link to="/">
            <img src={LogoImg} alt="Logo" className="mt-2" />
                </Link>
            </div>
            <HeaderMenu />
        </div>
    );
}

export default Header;