import React from "react";

const MainText = () => {
    return (
        <div className="flex flex-col justify-center items-center main-text mx-8 mb-12">
            <div className="w-4/5 md:w-2/5">
            <h1 className="text-2xl m-4">Marathion Mykonos</h1>
            <p className="text-xl my-6">Marathion is a family-owned business founded in 2023 in Marathi, Mykonos. We specialize in providing rental properties for the island's seasonal workers. We understand the specific housing requirements of those working on Mykonos and strive to provide comfortable and affordable options.</p>
            <p className="text-xl my-6">Our property portfolio includes a wide range of houses and apartments that are well-suited to the needs of seasonal workers. We have something to suit your needs and budget, whether you're looking for a studio or a multi-bedroom apartment. All of our properties are fully furnished and equipped with everything you need for a comfortable stay.</p>
            <p className="text-xl my-6">We take pride in our personalized service and knowledge of the island. Our family has been living in Mykonos for generations and we have a deep understanding of the local culture and lifestyle. We are committed to helping our guests feel at home and make the most of their stay on the island.</p>
            <p className="text-xl my-6">At Marathion, we understand the importance of finding suitable housing during the busy season and we work hard to ensure that our guests are happy and comfortable in their rental properties. We look forward to welcoming you to Mykonos and helping you make the most of your time on the island.</p>
            </div>
        </div>
    );
}

export default MainText;