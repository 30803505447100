import React, { useEffect, useRef } from 'react';

const HousesMap = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 37.449666, lng: 25.377586 }, // coordinates of Mykonos
      zoom: 12
    });

    const houses = [
      {
        name: "House 1",
        location: { lat: 37.461563, lng: 25.355084 }
      },
      {
        name: "House 2",
        location: { lat: 37.465956, lng: 25.359569 }
      },
      {
        name: "House 3",
        location: { lat: 37.461753, lng: 25.355084 }
      },
      {
        name: "House 4",
        location: { lat: 37.464355, lng: 25.359129 }
      }
    ];

    houses.forEach(house => {
      new window.google.maps.Marker({
        position: house.location,
        map: map,
        title: house.name
      });
    });

  }, [])

  return (
    <div className="w-full h-96" ref={mapRef} />
  )
}

export default HousesMap;