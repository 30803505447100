import React from "react";
import BackgroundVideo from "../components/BackgroundVideo";
import MainText from "../components/MainText";


const Home = () => {
    return (
        <>
        <BackgroundVideo />
        <MainText />
        </>
    );
    }

    export default Home;