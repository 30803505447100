import React from 'react';
import House1 from '../assets/house1.jpg';
import House2 from '../assets/house2.jpg';
import House3 from '../assets/house3.jpg';
import House4 from '../assets/house4.jpg';

const HousesGrid = () => {
  return (
    <>
    <h1 className='text-3xl mt-12'>Our Houses</h1>
    <div className="grid grid-cols-2 md:grid-cols-4 gap-12 mr-12 ml-12 mb-12 mt-12">
      <div className="col-span-1  bg-white pb-5">
        <img src={House1} alt="House1" className="w-full mr-12"/>
        <h3 className="text-lg font-medium text-center mt-2">House 1</h3>
        <p className="text-sm text-center">3 Bedrooms, 2 Bathrooms</p>
        <p className="text-sm text-center">500€/night</p>
      </div>
      <div className="col-span-1 bg-white pb-5">
        <img src={House2} alt="House2" className="w-full"/>
        <h3 className="text-lg font-medium text-center mt-2">House 2</h3>
        <p className="text-sm text-center">4 Bedrooms, 3 Bathrooms</p>
        <p className="text-sm text-center">800€/night</p>
      </div>
      <div className="col-span-1 bg-white pb-5">
        <img src={House3} alt="House3" className="w-full"/>
        <h3 className="text-lg font-medium text-center mt-2">House 3</h3>
        <p className="text-sm text-center">2 Bedrooms, 1 Bathroom</p>
        <p className="text-sm text-center">500€/night</p>
      </div>
      <div className="col-span-1 bg-white pb-5">
        <img src={House4} alt="House4" className="w-full"/>
        <h3 className="text-lg font-medium text-center mt-2">House 4</h3>
        <p className="text-sm text-center">3 Bedrooms, 2 Bathrooms</p>
        <p className="text-sm text-center ">600€/night</p>
      </div>
    </div>
    </>
  )
}

export default HousesGrid;
