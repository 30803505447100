import React from "react";
import PrivacyPolicyText from "../components/PrivacyPolicyText";


const PrivacyPolicy = () => {
    return (
        <>
        <PrivacyPolicyText />
        </>
    );
    }

    export default PrivacyPolicy;