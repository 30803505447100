import React from "react";
import ContactForm from "../components/ContactForm";


const Houses = () => {
    return (
        <ContactForm />
    );
    }

    export default Houses;