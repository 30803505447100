import React from "react";
import HousesMap from "../components/HouseMap";
import HousesGrid from "../components/HousesGrid";


const Houses = () => {
    return (
        <>
        <HousesMap />
        <HousesGrid />
        </>
    );
    }

    export default Houses;