import React from "react";

const PrivacyPolicyText = () => {
    return (
        <div className="flex flex-col justify-center items-center main-text mx-8 mb-12">
            <div className="w-4/5 md:w-2/5">
            <h1 className="text-2xl m-4">Privacy Policy</h1>
            <p className="text-xl my-6">Marathion is committed to protecting the privacy of our users and clients. This Privacy Policy explains how we collect, use, and disclose personal information when you use our website and services.
Collection and Use of Personal Information

We collect personal information when you use our website, including when you make a reservation, contact us, or register for an account. The personal information we collect includes your name, email address, phone number, and any other information you choose to provide.

We use your personal information to provide our services, including processing your reservations, responding to your inquiries, and communicating with you about your account or reservations. We may also use your personal information to improve our website and services and to send you promotional materials.

Disclosure of Personal Information

We do not sell or rent your personal information to third parties. We may disclose your personal information to third parties in the following circumstances:

To our service providers, such as payment processors and email service providers, to assist us in providing our services.
As required by law or in response to a legal request, such as a subpoena or court order.
To enforce our terms and conditions or to protect the rights, property, or safety of Marathion, our users, or others.
Security of Personal Information

We take reasonable measures to protect the personal information we collect from unauthorized access, use, or disclosure. However, no method of internet transmission or electronic storage is completely secure, and we cannot guarantee the security of your personal information.

Changes to this Privacy Policy

We may update this Privacy Policy from time to time. If we make changes to this Privacy Policy, we will post the updated policy on our website and update the "Last Updated" date at the top of this policy.

Contact Us

If you have any questions or concerns about this Privacy Policy, please contact us at info@marathion.com.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicyText;