import React from 'react';

const Footer = () => {
  return (
    
<footer className="bottom-0 left-0 w-full p-4 bg-white md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://marathion.gr/" class="hover:underline">Marathion</a>. All Rights Reserved. <a href="https://webee.gr/" className="hover:underline text-bold">Powered By Webee.gr</a>
    </span>
    <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <a href="/privacy-policy" className="mr-4 hover:underline md:mr-6 ">Privacy Policy</a>
        </li>
        <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">Phone: +30 6912345678</a>
        </li>
        <li>
            <a href="/" className="mr-4 hover:underline md:mr-6">Email: info@marathion.gr</a>
        </li>
        <li>
            <a href="/" className="hover:underline">Address: Marathi, Mykonos 84600</a>
        </li>
    </ul>
</footer>

  )
}

export default Footer;