import './App.css';
import './index.css';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Houses from './pages/Houses';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';


function App() {
  return (
    <Router>
    <div className="App" >
      <Header />
     <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/houses">
          <Houses />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
     </Switch>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
